import Layout from '@/components/Layout/Layout';
import SEO from '@/components/SEO/Seo';
import AllBrands from '@motokiki/shared/allBrands';
import GeneralHeroSec from '@motokiki/shared/generalHeroSec';
import { graphql } from 'gatsby';
import React from 'react';

import GeneralTextBlock from '../../components/GeneralTextBlock';
import SearchWidgetContainer from '../../components/SearchWidgetContainer';
import { BrandPages } from '../IndividualBrandsPage/individualBrandsPage';
import './CarManufacturerIndex.scss';

// interface Props {
//   data: {
//     strapi: {
//     }
//   }
// }

const CarManufacturerIndex = ({ data }) => {
  const {
    HeroSection,
    TextBlock,
    Seo,
    car_manufacturers,
  } = data.strapi.carBrandsIndex;

  const dynamicSort = (property) => {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return function (a, b) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    };
  };

  const alphabeticallySortedCarBrands = car_manufacturers.sort(
    dynamicSort('name'),
  );

  var link = (url: string) => {
    return url.toLowerCase().replace(' ', '_');
  };

  return (
    <>
      <SEO />
      <Layout>
        <GeneralHeroSec data={HeroSection} />
        <div className="pageContent">
          <GeneralTextBlock textData={TextBlock} />
          <h2 className="header-h2">Car Brands</h2>
        </div>
        <div className="carBrandsContainer">
          {alphabeticallySortedCarBrands.map((carBrand) => {
            return (
              <a
                className="brandCard"
                href={link(`/tyres/cars/${carBrand.name}`)}
                key={carBrand.name}
              >
                <div className="logoHolder">
                  <img className="logo" src={carBrand.logoLink} />
                </div>
                <div className="name">{carBrand.name}</div>
              </a>
            );
          })}
        </div>

        {/* <AllBrands brandData={alphabeticallySortedBrands} /> */}
        <SearchWidgetContainer />
      </Layout>
    </>
  );
};

export const carManufactureresIndex = graphql`
  query carBrandIndex {
    strapi {
      carBrandsIndex(publicationState: PREVIEW) {
        HeroSection {
          className
          mainImg {
            url
          }
          headerText
          activeLozenge
          displayIcon
          headerPrefix
          lozengeText
          iconImg {
            url
          }
        }
        TextBlock {
          textBlock
        }
        Seo {
          description
          title
        }
        car_manufacturers {
          logoLink
          name
        }
      }
    }
  }
`;

export default CarManufacturerIndex;
